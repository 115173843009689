.ant-input {
    border-radius: 4px;
}

.ant-input-password {
    border-radius: 4px;
}

.ant-checkbox {
    .ant-checkbox-inner {
        width: 20px !important;
        height: 20px !important;
        border: 2px solid #6b778c;
    }
}

.ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled {
    .ant-checkbox-inner {
        background-color: #AAB2BF;
        border-color: transparent !important;

        &::after {
            border-color: #fff !important;
        }
    }
}

.ant-checkbox.ant-checkbox-disabled {
    .ant-checkbox-inner {
        background-color: transparent;
        border-color: #AAB2BF;

        &::after {
            border-color: #fff !important;
        }
    }
}

.ant-checkbox.ant-checkbox-indeterminate.ant-checkbox-disabled {
    .ant-checkbox-inner {
        background-color: #AAB2BF;
        border-color: transparent !important;

        &::after {
            background-color: #fff !important;
        }
    }
}

.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0073c4;
    border-color: #0073c4;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #0073c4;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: #0073c4;
    border-color: transparent;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
    &:focus {
        border-color: #0073c4 !important;
    }
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #fff;
    height: 2px;
    width: 10px;
    border-radius: 4px;
}