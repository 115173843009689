@charset "UTF-8";

@font-face {
  font-family: "besins";
  src: url("/fonts/Besins/besins.eot");
  src: url("/fonts/Besins/besins.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Besins/besins.woff") format("woff"),
    url("/fonts/Besins/besins.ttf") format("truetype"),
    url("/fonts/Besins/besins.svg#besins") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "besins" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "besins" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\61";
}

.icon-add-box:before {
  content: "\62";
}

.icon-add-circle:before {
  content: "\63";
}

.icon-calendar:before {
  content: "\65";
}

.icon-cancel-circle:before {
  content: "\66";
}

.icon-check-circle:before {
  content: "\67";
}

.icon-close:before {
  content: "\68";
}

.icon-customer:before {
  content: "\69";
}

.icon-dasboard:before {
  content: "\6a";
}

.icon-delete:before {
  content: "\6b";
}

.icon-check:before {
  content: "\6c";
}

.icon-download:before {
  content: "\6d";
}

.icon-edit:before {
  content: "\6e";
}

.icon-eye-close:before {
  content: "\6f";
}

.icon-eye-open:before {
  content: "\70";
}

.icon-file:before {
  content: "\71";
}

.icon-help:before {
  content: "\72";
}

.icon-home:before {
  content: "\73";
}

.icon-menu:before {
  content: "\75";
}

.icon-menu-collapse:before {
  content: "\76";
}

.icon-more-horiz:before {
  content: "\77";
}

.icon-more-vert:before {
  content: "\78";
}

.icon-order-list:before {
  content: "\79";
}

.icon-print:before {
  content: "\7a";
}

.icon-refresh:before {
  content: "\41";
}

.icon-remove:before {
  content: "\42";
}

.icon-remove-circle:before {
  content: "\43";
}

.icon-remove-circle-outline:before {
  content: "\44";
}

.icon-search:before {
  content: "\45";
}

.icon-setting:before {
  content: "\46";
}

.icon-survey:before {
  content: "\47";
}

.icon-upload:before {
  content: "\48";
}

.icon-vertical-split:before {
  content: "\49";
}

.icon-view-column:before {
  content: "\4a";
}

.icon-unfold-more:before {
  content: "\64";
}

.icon-image-file:before {
  content: "\4b";
}

.icon-arrow-upward:before {
  content: "\4c";
}

.icon-arrow-right:before {
  content: "\4d";
}

.icon-arrow-next:before {
  content: "\4e";
}

.icon-account:before {
  content: "\4f";
}

.icon-account-circle:before {
  content: "\50";
}

.icon-account-rounded:before {
  content: "\51";
}

.icon-add-notification:before {
  content: "\52";
}

.icon-arrow-back:before {
  content: "\53";
}

.icon-arrow-downward:before {
  content: "\54";
}

.icon-arrow-drop-down:before {
  content: "\55";
}

.icon-arrow-drop-up:before {
  content: "\56";
}

.icon-arrow-left:before {
  content: "\57";
}

.icon-chevron-left:before {
  content: "\58";
}

.icon-cart:before {
  content: "\2f";
}

.icon-chevron-left-double:before {
  content: "\59";
}

.icon-chevron-right:before {
  content: "\5a";
}

.icon-chevron-right-double:before {
  content: "\30";
}

.icon-expand-more:before {
  content: "\31";
}

.icon-expand-less:before {
  content: "\32";
}

.icon-error-outline:before {
  content: "\33";
}

.icon-error:before {
  content: "\34";
}

.icon-notification:before {
  content: "\35";
}

.icon-notifications:before {
  content: "\36";
}

.icon-employee:before {
  content: "\37";
}

.icon-cloud-upload:before {
  content: "\38";
}

.icon-warning:before {
  content: "\39";
}

.icon-warning-amber:before {
  content: "\21";
}

.icon-toggle-off:before {
  content: "\22";
}

.icon-toggle-on:before {
  content: "\23";
}

.icon-radio-on:before {
  content: "\24";
}

.icon-radio-off:before {
  content: "\25";
}

.icon-indeterminate-check-box:before {
  content: "\26";
}

.icon-checked-box:before {
  content: "\27";
}

.icon-check-box-outline-blank:before {
  content: "\74";
}

.icon-image:before {
  content: "\28";
}

.icon-info:before {
  content: "\29";
}

.icon-add-circle-outline:before {
  content: "\2a";
}

.icon-file-export:before {
  content: "\2d";
}

.icon-done-24px-rounded:before {
  content: "\2c";
}

.icon-bullet-list-24px:before {
  content: "\2e";
}

.icon-add-person:before {
  content: "\2f";
}

.icon-logout-24px:before {
  content: "\2b";
}

.icon-lock-1:before {
  content: "\3a";
}
