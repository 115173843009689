
@charset "UTF-8";
@import "bootstrap/scss/bootstrap.scss";
@import "./customs/variables.scss";
@import "./customs/text.scss";
@import "../../../node_modules/antd/dist/antd.css";
@import "./antd.scss";


body {
  font-family: "IBM Plex Sans", sans-serif;
}

h1, h2, h3, h4, h5 {
  margin-bottom: 0 !important;
}

#root{
  height: 100vh;
}

a {
  &:hover {
    text-decoration: none;
  }
}
img {
  max-width: 100%
}