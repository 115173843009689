:global {
  @import "./customs/variables.scss";
  @import "./customs/mixins.scss";

  .text-ellipsis {
    @include text-ellipsis();
  }
  .full-width {
    width: 100%;
  }
  .flex-center {
    @include flex-center();
  }
}
