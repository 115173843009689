.notification-socket {
  &.success {
    .ant-notification-notice-content {
      .ant-notification-notice-message {
        color: #389e0d !important;
      }
    }
  }
  &.error {
    .ant-notification-notice-content {
      .ant-notification-notice-message {
        color: #f45b19 !important;
      }
    }
  }
  .ant-notification-notice-icon {
    display: flex;
    align-items: center;
  }
  .ant-notification-notice-message {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    margin-left: 40px !important;
    line-height: 20px !important;
  }
  .ant-notification-notice-description {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    color: #6b778c !important;
    color: #42526e !important;
    margin-left: 40px !important;
  }
}
