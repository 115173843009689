@import "../mixins/transition";

@mixin text($inkTxt, $fsTxt, $fwTxt, $lhTxt) {
  color: $inkTxt;
  font-size: $fsTxt;
  font-weight: $fwTxt;
  line-height: $lhTxt;
}
@mixin text-ellipsis() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@mixin flex-center($display: flex) {
  display: $display;
  justify-content: center;
  align-content: center;
  align-items: center;
}

@mixin ellipsis-text($display: flex) {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

@mixin flex(
  $display: flex,
  $flex-direction: row,
  $justify-content: center,
  $align-item: center
) {
  display: $display;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-item;
}
