// brand color 
$primary: #0073C4;
$primary-lighter: #E8F5FF;
$primary-light: #45A4E6;
$primary-dark: #005E9E;


// Primary color
$primary-black: #292929;
$primary-white: #ffffff;

// Secondary color
$secondary-basic: #00773e;
$secondary-lighter: #009e52;
$secondary-link: #295baa;
$secondary-link-lighter: #3172d8;

// Ink color
$ink: #172B4D;
$ink-light: #42526E;
$ink-lighter: #6B778C;

// Background color
$bg-lighter: #d9d9d9;
$bg-lightest: #f7f7f7;
$bg-footer: #f7f5f2;
$bg-dark: #1e1e1e;

// Accent color
$color-error: #ed2c2c;
$color-success: #3dcc6d;
$color-warning: #faa532;

//Text color
$text-light: #757575;
$text-dark: #dddddd;

//Body text color
$body-light: #353535;
$body-dark: #dddddd;

//sky
$sky-lighter: #E6E8EB;

//Font Size
$fs-0: 14px;
$fs-1: 32px;
$fs-2: 26px;
$fs-3: 20px;
$fs-4: 16px;
$fs-5: 12px;
$fs-subtitle-1: 13px;
$fs-subtitle-2: 12px;
$fs-bodytext-1: 18px;

//Font Weight
$fw-normal: 400;
$fw-light: 300;
$fw-bold: 500;
$fw-semi-bold: 600;
$fw-medium:500;

//Line height
$lh-0: normal;
$lh-1: 54px;
$lh-2: 44px;
$lh-3: 32px;
$lh-4: 28px;
$lh-5: 22px;
$lh-6: 20px;
$lh-7: 18px;
$lh-8: 16px;
$lh-9: 24px;

//Letter spacing
$ls-1: 0.01em;
$ls-2: 0.005em;

// Transitions
$transition-base: all .2s ease-in-out !default;
$transition-fade: opacity .15s linear !default;
$transition-collapse: height .35s ease !default;
$transition-base-time: .15s;
$transition-base: all $transition-base-time ease !default;
$transition-fade: opacity $transition-base-time linear !default;
$transition-collapse: height $transition-base-time * 2 ease !default;
$transition-cubic-bezier: all $transition-base-time cubic-bezier(.68, -0.55, .265, 1.55) !default;
$transition-bg: background-color $transition-base-time * 2 linear !default;
$transition-ease-in-out: all $transition-base-time ease-in-out !default;

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true !default;
$enable-print-styles: true !default;
