@import "./mixins.scss";

@font-face {
    font-family: 'IBM Plex Sans';
    src: url("/fonts/IBM/IBMPlexSans-ExtraLight.ttf") format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url("/fonts/IBM/IBMPlexSans-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url("/fonts/IBM/IBMPlexSans-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url("/fonts/IBM/IBMPlexSans-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url("/fonts/IBM/IBMPlexSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url("/fonts/IBM/IBMPlexSans-Bold.ttf") format("truetype");
    font-weight: 700;
}


body {
    font-family: 'IBM Plex Sans', sans-serif;
}

// Ink
h1 {
    @include text($ink !important, $fs-1, $fw-bold !important, $lh-1 !important);
    letter-spacing: $ls-1;
}

h2 {
    @include text($ink !important, $fs-2, $fw-semi-bold !important, $lh-2 !important);
}

h3 {
    @include text($ink !important, $fs-3, $fw-semi-bold !important, $lh-3 !important);
}

h4 {
    @include text($ink !important, $fs-4, $fw-semi-bold !important, $lh-4 !important);
}

h5 {
    @include text($ink !important, $fs-5, $fw-semi-bold !important, $lh-5 !important);
}

.subtitle-1 {
    @include text($ink, $fs-subtitle-1, $fw-semi-bold, $lh-7);
    letter-spacing: $ls-1;
}

.subtitle-2 {
    @include text($ink, $fs-subtitle-2, $fw-normal, $lh-8);
    letter-spacing: $ls-2;
}

button {
    @include text($ink, $fs-0, $fw-medium, $lh-6);
}

.text-body-1 {
    @include text($ink, $fs-bodytext-1, $fw-light, $lh-3);
    font-family: 'Merriweather', serif;
}

.text-body-2 {
    @include text($ink, $fs-0, $fw-normal, $lh-6);
}

.caption {
    @include text($ink, $fs-0, $fw-normal, $lh-9);
    font-family: 'Merriweather', serif;
    font-feature-settings: 'pnum'on, 'lnum'on;
}

.caption-1 {
    @include text($ink-lighter, $fs-5, $fw-semi-bold, $lh-8);
    font-family: 'IBM Plex Sans', serif;
}

.subtitle-3{
    @include text($ink, $fs-0, $fw-semi-bold, $lh-6);
    font-family: 'IBM Plex Sans', serif;
}

.text-bold {
    font-weight: bold;
    color: $ink;
}

.text-italic {
    font-style: italic;
    color: $ink;
}

.text-underline {
    text-decoration: underline;
    color: $ink;
}

p {
    @include text($ink-light, $fs-0, $fw-normal, $lh-0);
}

// Besins Typography
.ink-title {
    @include text($ink, $fs-4, $fw-semi-bold, $lh-9);
}

.ink-light-title {
    @include text($ink-light, $fs-4, $fw-semi-bold, $lh-9);
}

.subdued-tilte {
    @include text($ink-lighter, $fs-4, $fw-semi-bold, $lh-9);
}

.ink-heading3 {
    @include text($ink, $fs-3, $fw-semi-bold, $lh-4);
}

.subdued-heading3 {
    @include text($ink-lighter, $fs-3, $fw-semi-bold, $lh-4);
}

.ink-caption1 {
    @include text($ink, $fs-5, $fw-semi-bold, $lh-8);
}

.ink-light-caption1 {
    @include text($ink-light, $fs-5, $fw-semi-bold, $lh-8);
}

.ink-lighter-caption1 {
    @include text($ink-lighter, $fs-5, $fw-semi-bold, $lh-8);
}

.subdued-caption1 {
    @include text($ink-lighter, $fs-5, $fw-semi-bold, $lh-8);
}

.ink-caption2 {
    @include text($ink, $fs-5, $fw-normal, $lh-8);
}

.ink-lightest-caption2 {
    @include text(#AAB2BF, $fs-5, $fw-normal, $lh-8);
}

.subdued-caption2 {
    @include text($ink-lighter, $fs-5, $fw-normal, $lh-8);
}

.ink-body1 {
    @include text($ink, $fs-0, $fw-normal, $lh-6);
}

.ink-light-body1 {
    @include text($ink-light, $fs-0, $fw-normal, $lh-6);
}

.subdued-body1 {
    @include text($ink-lighter, $fs-0, $fw-normal, $lh-6);
}

.ink-subtitle1 {
    @include text($ink, $fs-0, $fw-semi-bold, $lh-6);
    letter-spacing: $ls-1;
    text-transform: uppercase;
}

.subdued-subtitle1 {
    @include text($ink-lighter, $fs-0, $fw-semi-bold, $lh-6);
    letter-spacing: $ls-1;
    text-transform: uppercase;
}

.ink-subtitle2 {
    @include text($ink, $fs-0, $fw-semi-bold, $lh-6);
}

.ink-light-subtitle2 {
    @include text($ink-light, $fs-0, $fw-semi-bold, $lh-6);
}

.subdued-subtitle2 {
    @include text($ink-lighter, $fs-0, $fw-semi-bold, $lh-6);
}
.text-all{
    color: $ink;
    position: relative;
    bottom: 1px;
}